import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { SERVER_URL } from '@/env-vars'
import { Store } from '@/store'
import { ExtendedChatMessage } from '@/store/types'
import { useAuthFromStorage } from '@/hooks/useAuthStorage'
import { toast } from 'sonner'
import { errors } from '@/i18n/errors'

export function useRetrieveChat() {
	const { authToken } = useAuthFromStorage()

	return useMutation({
		mutationKey: ['retrieve-chat'],
		mutationFn: async ({ chatId }: { chatId: string }) => {
			const response = await axios.get(`${SERVER_URL}/chat/id/${chatId}`, {
				headers: { Authorization: `Bearer ${authToken}` },
			})

			if (response.status < 400) {
				return response.data
			} else if (response.status >= 500) {
				throw new Error('Server Error')
			} else if (response.status == 404) {
				return null
			}
		},
		onSuccess: (data) => {
			if (data) {
				const { chat, starredMessages } = data

				const starredMessageContents = new Set(
					starredMessages?.map((message: ExtendedChatMessage) => message.content),
				)

				// enriching messages with isStarred flag
				const enrichedMessages = chat.messages.map((message: ExtendedChatMessage) => ({
					...message,
					isStarred: message.role === 'user' &&
						starredMessageContents.has(message.content),
				}))

				Store.updateChats({
					newChats: [{ ...chat, messages: enrichedMessages }],
					updateMessages: true,
				})
			}
		},
		onError: () => {
			toast(errors.SERVER_ERROR, {
				dismissible: true,
				action: {
					label: 'Ok',
					onClick: () => {},
				},
				duration: 5 * 1000,
			})
		},
	})
}
