import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { SERVER_URL } from '@/env-vars'
import { useAuthFromStorage } from '@/hooks/useAuthStorage'
import { StarredMessage } from '@/store/types'

export function useListVideos() {
	const { authToken } = useAuthFromStorage()

	return useQuery({
		queryKey: ['list-starred-messages'],
		queryFn: async () => {
			const response = await axios.get(`${SERVER_URL}/messages/starred`, {
				headers: { Authorization: `Bearer ${authToken}` },
			})
			return response.data.starredMessages as StarredMessage[]
		},
	})
}
