import { useListVideos } from '@/hooks/useListStarred'
import { useState } from 'react'
import StarMessage from '@/components/StarMessage'
import { StarredMessage } from '@/store/types'
import Spinner from '@/components/Spinner'
import { ArrowLeft, Search } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

const StarredMessagesPage = () => {
	const navigate = useNavigate()
	const { data: starredMessages, isLoading } = useListVideos()
	const [expandedMessageIndex, setExpandedMessageIndex] = useState<number | null>(null)
	const [isSearchBarOpen, setIsSearchBarOpen] = useState(false)
	const [searchQuery, setSearchQuery] = useState('')

	const handleViewAnswer = (index: number) => {
		setExpandedMessageIndex(index === expandedMessageIndex ? null : index)
	}

	const filteredMessages = starredMessages?.filter((message: StarredMessage) => {
		const userMessage = message.userMessage.type === 'text' ? message.userMessage.content : ''
		const assistantMessage = message.assistantMessage.type === 'text' ? message.assistantMessage.content : ''
		const combinedText = `${userMessage} ${assistantMessage}`.toLowerCase()
		return combinedText.includes(searchQuery.toLowerCase())
	})

	if (isLoading) {
		return (
			<div className='flex flex-col h-screen bg-[#151518]'>
				<div className='flex items-center justify-center h-screen'>
					<Spinner />
				</div>
			</div>
		)
	}

	return (
		<div className='flex flex-col h-screen bg-[#151518]'>
			{isSearchBarOpen
				? (
					<div className='flex items-center px-4 h-14'>
						<button onClick={() => setIsSearchBarOpen(false)} className='p-2 -ml-2'>
							<ArrowLeft className='w-6 h-6 text-[#929093]' />
						</button>
						<input
							type='text'
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
							className='flex-1 bg-[#2C2C2C] h-10 px-4 text-white rounded-xl focus:outline-none focus:ring-1 focus:ring-zinc-700 placeholder:text-[#929093]'
							placeholder='Search starred messages...'
						/>
					</div>
				)
				: (
					<div className='flex items-center px-4 h-14'>
						<button onClick={() => navigate(-1)} className='p-2 -ml-2'>
							<ArrowLeft className='w-6 h-6 text-[#929093]' />
						</button>
						<h1 className='flex-1 text-center text-white text-base font-medium'>Starred Messages</h1>
						<button onClick={() => setIsSearchBarOpen(true)} className='p-2'>
							<Search className='w-6 h-6 text-[#929093]' />
						</button>
					</div>
				)}
			<hr className='h-0.5 mb-2 mt-2 bg-[#1F1F1F] border-black' />

			{(!filteredMessages || filteredMessages.length === 0)
				? (
					<div className='flex flex-col items-center justify-center flex-1 p-4 text-center'>
						<p className='text-lg font-medium text-white mb-2'>No starred messages found</p>
						<p className='text-sm text-zinc-400'>Try searching for something else</p>
					</div>
				)
				: (
					<div className='flex-1 overflow-y-auto p-4'>
						{filteredMessages.map((message: StarredMessage, index: number) => (
							<div key={index} className='mb-4'>
								<div className='flex flex-col'>
									{message.userMessage.type === 'text' && (
										<div className='flex justify-end'>
											<div className='max-w-[80%]'>
												<StarMessage
													message={message.userMessage}
													chatId={message.chatId}
													showViewAnswer
													isExpanded={expandedMessageIndex === index}
													onViewAnswer={() => handleViewAnswer(index)}
												/>
											</div>
										</div>
									)}

									{expandedMessageIndex === index && message.assistantMessage.type === 'text' && (
										<div className='flex justify-start mt-2'>
											<div className='max-w-[80%]'>
												<StarMessage
													message={message.assistantMessage}
													chatId={message.chatId}
												/>
											</div>
										</div>
									)}
								</div>
							</div>
						))}
					</div>
				)}
		</div>
	)
}

export default StarredMessagesPage
