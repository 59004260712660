import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { SERVER_URL } from '@/env-vars'
import { useAuthFromStorage } from '@/hooks/useAuthStorage'
import { ExtendedChatMessage } from '@/store/types'
import { Store } from '@/store'

export function useUnstarMessage() {
	const { authToken } = useAuthFromStorage()

	return useMutation({
		mutationFn: async (payload: { userMessage: ExtendedChatMessage; chatId: string }) => {
			return axios.delete(`${SERVER_URL}/messages/unstar`, {
				headers: { Authorization: `Bearer ${authToken}` },
				data: payload,
			})
		},
		onSuccess: (_, { userMessage, chatId }) => {
			Store.setState((draft) => {
				const chat = draft.chats[chatId]
				if (!chat) return

				const messageIndex = chat.messages.findIndex(
					(msg) => msg.role === 'user' && msg.content === userMessage.content,
				)

				if (messageIndex !== -1) {
					chat.messages[messageIndex].isStarred = false
				}
			})
		},
	})
}
