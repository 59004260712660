import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { SERVER_URL } from '@/env-vars'
import { useAuthFromStorage } from '@/hooks/useAuthStorage'
import { ExtendedChatMessage } from '@/store/types'
import { Store } from '@/store'

export function useStarMessage() {
	const { authToken } = useAuthFromStorage()

	return useMutation({
		mutationFn: async (payload: {
			userMessage: ExtendedChatMessage
			assistantMessage: ExtendedChatMessage
			chatId: string
		}) => {
			return axios.post(`${SERVER_URL}/messages/star`, payload, {
				headers: { Authorization: `Bearer ${authToken}` },
			})
		},
		onSuccess: (_, { userMessage, chatId }) => {
			Store.setState((draft) => {
				const chat = draft.chats[chatId]
				if (!chat) return

				const messageIndex = chat.messages.findIndex(
					(msg) => msg.role === 'user' && msg.content === userMessage.content,
				)
				if (messageIndex !== -1) {
					chat.messages[messageIndex].isStarred = true
				}
			})
		},
	})
}
